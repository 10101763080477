<template>
<section class="domain_content">
    <ModList :modList="modList" />
    <RecentPost :doc="doc" />

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import ModList from '@/components/ModList.vue';
import RecentPost from '@/components/RecentPost.vue';
import doc from '@/documents/blog3.js';

export default {
    ...{
        "metaInfo": {
            "title": "Products List of Popular WhatsApp Mods for Users",
            "meta": [{
                    "name": "description",
                    "content": "Some useful WhatsApp Mods are introduced in this page. Enter the specific page to learn more about these apps."
                }, {
                    "name": "title",
                    "content": "Products List of Popular WhatsApp Mods for Users"
                }, {
                    "name": "keywords",
                    "content": "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download"
                },
                {
                    "property": "og:title",
                    "content": "Products List of Popular WhatsApp Mods for Users"
                }, {
                    "property": "og:description",
                    "content": "Some useful WhatsApp Mods are introduced in this page. Enter the specific page to learn more about these apps."
                }
            ],
            "link": [{
                "rel": "canonical",
                "href": "https://gbwhatsapk.net/more-mods/"
            }]
        }
    },
    components: {
        ModList,
        RecentPost
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            doc: doc,
            modList: [{
                    title: "GB WhatsApp APK",
                    url: "/", // add URL if needed
                    img: require('@/assets/mod-icon-1.webp'), // replace with actual image path
                    alt: "GB WhatsApp APK v18.20",
                    version: "v18.20"
                },
                {
                    title: "OG WhatsApp APK",
                    url: "/og-whatsapp-apk", // add URL if needed
                    img: require('@/assets/mod-icon-8.webp'), // replace with actual image path
                    alt: "OG WhatsApp APK v62",
                    version: "v62"
                }
            ]

        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        goback() {
            this.$router.push('/');
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
